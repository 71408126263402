import * as React from 'react';
import { graphql } from 'gatsby';

import { useLanguageNavigateEffect } from '~/hooks/useLanguageNavigateEffect';
import SEO from '~/components/SEO';

import type { PageProps } from 'gatsby';

type IndexPageProps = PageProps<GatsbyTypes.IndexPageQuery>;

const IndexPage: React.FC<IndexPageProps> = ({ location, data }) => {
  const languages = data
    .allStrapiPolicyMain
    .nodes
    .map((node) => node.language)
    .filter(Boolean) as string[];

  useLanguageNavigateEffect(languages);

  return <SEO language={'ko'} description={'Devsisters Policies'} pathname={location.pathname} />;
};

export default IndexPage;

export const query = graphql`
  query IndexPage {
    allStrapiPolicyMain {
      nodes {
        language
      }
    }
  }
`;
